

function Nos() {
    const imageTechnologyUrls = [
        'images/technologies/catalyst.png',
        'images/technologies/dbix.png',
        'images/technologies/mysql.png',
        'images/technologies/perl.png',
    ];

    return (
        <div className={'nimber-case-wrapper'}>
            <div>
                <div>
                    <img src={'images/clients/normal/nos.png'} alt={'Nos logo'}  style={{ paddingBottom: '32px'}}/>
                    <h1 className={'nimber-main-title-digital'}>Data Center Portal</h1>
                    <p className={'nimber-texts'}>The main objective is to update the portal with the latest version of Catalyst and Perl language, create new endpoints, improve application performance, and resolve any bottlenecks within the project at the application, server, and database level.</p>
                </div>
                <div>
                    <h3 className={'nimber-sub-section-titles'}>Main Technologies</h3>
                    <div className={'nimber-case-techs'}>
                        {imageTechnologyUrls.map((imageUrl, index) => (
                            <div key={`nimberCaseTechsNos${index}`}>
                                <img key={`Nos${index}`} src={imageUrl} alt={`a ${index}`} className="gallery-image" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nos