import '../styles/tabs.css'

const Tab = ({ label, onClick, active }) => {
    return (
        <div className={`tab ${active ? 'active' : ''}`} onClick={onClick}>
            <span>{label}</span>
        </div>
    );
};

export default Tab;