import React from 'react';
import OurValuesCard from '../components/OurValuesCard';
import '../styles/ourValues.css';

const OurValues = () => {
    return (
        <>
            <div className='ourValues'>
                <OurValuesCard>
                    <div>
                        <img className='ourValues-img' src='images/ourValues/01.png' />
                        <p className='ourValues-text'>
                            Transparency, Truth, and Sincerity
                        </p>
                    </div>
                </OurValuesCard>
                <OurValuesCard>
                    <div>
                        <img className='ourValues-img' src='images/ourValues/02.png' />
                        <p className='ourValues-text'>
                            Diversity and Respect
                        </p>
                    </div>
                </OurValuesCard>
                <OurValuesCard>
                    <div>
                        <img className='ourValues-img' src='images/ourValues/03.png' />
                        <p className='ourValues-text'>
                            Responsibility and Loyalty
                        </p>
                    </div>
                </OurValuesCard>
            </div>
        </>
    );
};

export default OurValues;