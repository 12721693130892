import Tab from "./Tab";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/tabs.css'
import {useEffect, useState} from "react";

const Tabs = ({ tabs, selectedTab, onSelectTab }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1000);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const settings2 = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 0,
        variableWidth: false,
        responsive: [
            {
                breakpoint: 950, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    afterChange: current => setCurrentPage(current),
                }
            },
            {
                breakpoint: 600, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    afterChange: current => setCurrentPage(current),
                }
            }
        ]
    };

    const setCurrentPage = (current) => {
        console.log(current)
        onSelectTab(current)
    }

    if (isMobile) {
        return (
            <div>
                <Slider {...settings2}>
                    {tabs.map(tab => (
                        <div key={tab.id}>
                            <Tab
                                label={tab.label}
                                onClick={() => onSelectTab(tab.id)}
                                active={selectedTab === tab.id}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }

    // Desktop view with Tabs
    return (
        <div className="tabs">
            {tabs.map(tab => (
                <Tab
                    key={tab.id}
                    label={tab.label}
                    onClick={() => onSelectTab(tab.id)}
                    active={selectedTab === tab.id}
                />
            ))}
        </div>
    );
};

export default Tabs;