import React from 'react';
import ContentImage from '../components/ContentImage';
import IconButton from '../components/IconButton';
import '../styles/meetNewWayConsulting.css';

const MeetNewWayConsulting = () => {
    return (
        <>
            <ContentImage
                imgSrc={'images/sections/section01-img.png'}
                imagePosition={'right'}>
                <div className='section01-text'>
                    <p>
                        We are an authentic, dynamic and forward-thinking company playing the game of advanced technology consultancy.
                        With our talented team, we achieve long-lasting impact with nimble solutions.
                    </p>
                </div>
            </ContentImage>
        </>
    );
};

export default MeetNewWayConsulting;