import React from 'react';
import ContentImage from '../components/ContentImage';
import '../styles/ourPersonality.css';

const OurPersonality = () => {
    return (
        <>
            <ContentImage
                imgSrc={'images/sections/section02A-img.png'}
                imagePosition={'left'}>
                <div className='section02a'>
                    <h2 className='section02a-h2'>HUMAN</h2>
                    <p className='section02a-text02'>
                        Understanding, compassion, and valuing human connection. Placing employees at the core, fostering motivation and integration.
                        It's about people, not just numbers. Creating a supportive journey, guiding individual paths.
                        Emphasizing cohesiveness, managing, and accompanying. Resolute in making a difference.
                    </p>
                </div>
            </ContentImage>
            <ContentImage
                imgSrc={'images/sections/section02B-img.png'}
                imagePosition={'invert'}
                additionalImgClass={'section02b-img'}>
                <div className='section02b'>
                    <h2 className='section02b-h2'>CHARISMATIC</h2>
                    <p className='section02b-text01'>
                        Numbers are transformative, not the transformer. Communication must be relatable, devoid of arrogance.
                        Acknowledging leadership prowess. Prioritizing values, principles, and dedication over mere experience.
                        It's about the person answering the phone at 9 PM. A drive to learn, evolve, and grow prevails.
                    </p>
                </div>
            </ContentImage>
            <ContentImage
                imgSrc={'images/sections/section02C-img.png'}
                imagePosition={'left'}>
                <div className='section02a'>
                    <h2 className='section02a-h2'>NEXT AND EMPATHETIC</h2>
                    <p className='section02a-text02'>
                        Fostering connection, closeness, and bridges. Sales management intertwined with relationship cultivation.
                        Nurturing team growth and career paths. Human connection, humility, and horizontal collaboration.
                    </p>
                </div>
            </ContentImage>
        </>
    )
};

export default OurPersonality;