import React from "react";
import '../styles/sections.css';

const SectionTitle = ({ title, imgSrc }) => {
    return (
        <div className="section-title">
            <img src={imgSrc} />
            <h3 className="section-title-text">{ title }</h3>
        </div>
    );
};

export default SectionTitle;