import React from "react";
import '../styles/ourValuesCard.css';

const OurValuesCard = ({ children }) => {
    return (
        <div className="card">
            {children}
        </div>
    );
};

export default OurValuesCard;