import React, { useState } from 'react';
import TechEdgeCard from '../components/TechEdgeCard';
import TechEdgeContainer from './TechEdgeContainer';
import '../styles/techEdge.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import techEdgeData from './TechEdge/techEdge.json';


const TechEdge = () => {
    const [cardSelected, setCardSelected] = useState("Software Engineering");
    const [techEdgeSelected, setTechEdgeSelected] = useState(techEdgeData[0]);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 0,
        variableWidth: false,
        responsive: [
            {
                breakpoint: 768, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    afterChange: current => setCurrentPage(current),
                }
            }
        ]
    };

    const handleCardClick = (techEdgeItem) => {
        setCardSelected(techEdgeItem.tech);
        setTechEdgeSelected(techEdgeItem);
    };

    const setCurrentPage = (current) => {
        setCardSelected(techEdgeData[current].tech)
        setTechEdgeSelected(techEdgeData[current])
    }

    return (
        <div className={'techedge-content'}>
            <div className='techedge-content-text'>
                <h2 className='techedge-subtitle'>Our Technology stack.</h2>
                <p className='techedge-description-text'>
                    Discover our comprehensive range of cutting-edge technologies meticulously curated to offer you a streamlined experience
                    and elevate your projects to their maximum capabilities. Irrespective of the complexity, our adaptable expertise
                    guarantees the perfect technological fit for exceptional outcomes.
                </p>
            </div>
            <div>
                <div className='techedge-cards'>
                    <Slider  {...settings}>
                        {techEdgeData.map((item, index) => (
                            <TechEdgeCard
                                key={`techEdgeCard${index}`}
                                techEdgeItem={item}
                                onClick={() => handleCardClick(item)}
                                selected={cardSelected === item.tech} />
                        ))}
                    </Slider>
                </div>
                <TechEdgeContainer techEdgeItem={techEdgeSelected} />
            </div>
        </div>
    );
};

export default TechEdge;