import React from 'react';
import '../styles/carrousel.css'
import InfiniteLooper from "./InfiniteLooper";

const images = [
    'images/clients/grey/Anticimex.png',
    'images/clients/grey/Cofidis.png',
    'images/clients/grey/Galp.png',
    'images/clients/grey/Nos.png',
    'images/clients/grey/SIBS.png',
];

const ClientCarrousel = () => {
    return (
        <div style={{ maxWidth: '100vw' }}>
            <InfiniteLooper speed={12} direction={"right"}>
                <div className={'looped-block'}>
                    <img src={images[0]} alt={'anticimex'}/>
                    <img src={images[1]} alt={'cofidis'}/>
                    <img src={images[2]} alt={'galp'}/>
                    <img src={images[3]} alt={'nos'}/>
                    <img src={images[4]} alt={'Sibs'}/>
                </div>
            </InfiniteLooper>
        </div>
    );
};

export default ClientCarrousel;