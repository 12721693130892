import { useState } from "react";
import Anticimex from "./CaseStudyPages/Anticimex";
import Galp from "./CaseStudyPages/Galp";
import Nos from "./CaseStudyPages/Nos";
import Ericsson from "./CaseStudyPages/Ericsson";
import Tabs from "./Tabs";
import '../styles/digitalTraces.css'

const DigitalTraces = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const tabs = [
        { id: 0, label: 'Architecture Solution', content: Galp },
        { id: 1, label: 'Transforming and creating a new platform for Anticimex', content: Anticimex },
        { id: 2, label: 'Data Center Portal', content: Nos },
        { id: 3, label: 'Manage the purchasing and supply of services related to the operation of Ericsson', content: Ericsson }
    ];

    const CurrentComponent = tabs.find(tab => tab.id === selectedTab).content;

    return (
        <div className={'digital-traces-wrapper'}>
            <div>
                <Tabs tabs={tabs} selectedTab={selectedTab} onSelectTab={setSelectedTab} />
            </div>
            <div className={'mobile-padding'}>
                <CurrentComponent />
            </div>
        </div>
    )
}

export default DigitalTraces