

function Galp() {
    const imageTechnologyUrls = [
        'images/technologies/Confluence.png',
        'images/technologies/drawio.png',
        'images/technologies/miro.png',
    ];

    return (
        <div className={'nimber-case-wrapper'} key={'Galp'}>
            <div>
                <div>
                    <img src={'images/clients/normal/Galp.png'} alt={'Galp logo'} style={{ paddingBottom: '32px'}}/>
                    <h1 className={'nimber-main-title-digital'}>Architecture Solution</h1>
                    <p className={'nimber-texts'}>The Enterprise Architecture & Technology’s (EA&T) mission is to enhance Galp's value by identifying new technology and optimizing IT assets. Our collaboration with business and IT departments defines quality standards and guidelines for Galp's digital transformation, making them a strategic partner for operational and business excellence.</p>
                </div>
                <div>
                    <h3 className={'nimber-sub-section-titles'}>Main Technologies</h3>
                    <div className={'nimber-case-techs'}>
                        {imageTechnologyUrls.map((imageUrl, index) => (
                            <div key={`nimberCaseTechsGalp${index}`}>
                                <img key={`Galp${index}`} src={imageUrl} alt={`a ${index}`} className="gallery-image" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Galp