

function Ericsson() {
    const imageTechnologyUrls = [
        'images/technologies/outsystems.png',
    ];

    return (
        <div className={'nimber-case-wrapper'}>
            <div>
                <div>
                    <img src={'images/clients/normal/Ericsson.png'} alt={'Ericsson logo'}  style={{ paddingBottom: '32px'}}/>
                    <h1 className={'nimber-main-title-digital'}>Manage the purchasing and supply of services related to the operation of Ericsson</h1>
                    <p className={'nimber-texts'}>Development of a software for company project and resource management, through project planning and monitoring. The project was developed using a 4 Layer canvas architecture with an internal database and several integrations with other systems to obtain data related to materials and their values and for communication with suppliers.</p>
                </div>
                <div>
                    <h3 className={'nimber-sub-section-titles'}>Main Technologies</h3>
                    <div className={'nimber-case-techs'}>
                        {imageTechnologyUrls.map((imageUrl, index) => (
                            <div key={`nimberCaseTechsEr${index}`}>
                                <img key={`Ericsson${index}`} src={imageUrl} alt={`a ${index}`} className="gallery-image" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ericsson