import { useEffect, useState } from 'react';
import '../styles/techEdgeContainer.css';

const TechEdgeContainerButton = ({ item, selected, onClick }) => {

    let selectedClass = 'tech-edge-container-button-unselected';

    if (selected) {
        selectedClass = 'tech-edge-container-button-selected';
    }

    return (
        <button className={selectedClass} onClick={onClick}>
            {item}
        </button>
    );
};

const TechEdgeContainer = ({ techEdgeItem }) => {
    const { topics } = techEdgeItem;
    const buttons = topics;
    const [selectedButton, setSelectedButton] = useState(null);

    useEffect(() => {
        setSelectedButton(buttons[0]);
    }, [buttons, techEdgeItem]);

    if (!techEdgeItem) {
        return (<></>);
    }
    const handleButtonClick = btn => {
        if (btn.subject == selectedButton?.subject) {
            setSelectedButton(null);
        } else {
            setSelectedButton(btn);
        }
    };

    return (
        <div className="tech-edge-container">
            <div className="tech-edge-container-buttons">
                {buttons?.map((item, index) => (
                    <TechEdgeContainerButton 
                        key={`techEdgeContainerButton${index}`}
                        item={item.subject} 
                        selected={selectedButton?.subject == item?.subject}
                        onClick={() => handleButtonClick(item)} />
                ))}
            </div>
            <div className="tech-edge-container-content">
                <p>{selectedButton?.description}</p>
            </div>
            {/*<div className="tech-edge-container-logos">*/}
            {/*    {selectedButton?.techStack?.map((item, index) => (*/}
            {/*        <img key={`techEdgeContainerLogo${index}`} src={`images/technologies/${item}`} alt={item} />*/}
            {/*    ))}*/}
            {/*</div>*/}
        </div>
    );
};

export default TechEdgeContainer;