import '../styles/contentImage.css';

const ContentImage = ({ imgSrc, imagePosition, additionalImgClass, children }) => {
    var imgClass = 'img-align';

    if (additionalImgClass) {
        imgClass += ' ' + additionalImgClass;
    }

    return (
        <div>
            <div className={`content-block ${imagePosition === 'invert' ? 'content-block-right' : ''}`}>
                {imagePosition === 'left' && (
                    <>
                        <div className={imgClass}>
                            <img src={imgSrc} />
                        </div>
                        {children}
                    </>
                )}
                {imagePosition !== 'left' && (
                    <>
                        {children}
                        <div className={imgClass}>
                            <img src={imgSrc} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ContentImage;